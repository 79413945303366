<script setup lang="ts"></script>

<template>
  <NuxtPwaManifest />
  <div class="h-screen bg-primary-50">
    <slot />
    <UNotifications />
  </div>
</template>

<style scoped></style>
